// todo: ensure title accomodates singular and plural

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import { Arrow } from 'untitledui-js';
import Card from 'components/pageECoach/allChats/Card';
import { Divider } from '@mui/material';

// styles
import * as AllChats from 'components/pageECoach/_styles/AllChats.style';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

export default function ToggleGroup({
  chats,
  getSession,
  handleConfirmDialogOpen,
  hidden,
  resetChatGuideData,
  setActivePage,
  toggleGroup,
  type,
}) {
  const { t } = useTranslation('pageEcoach');

  return (
    <>
      <AllChats.ToggleGroup onClick={toggleGroup}>
        {!hidden ? <Arrow.ChevronDown /> : <Arrow.ChevronRight />}
        <AllChats.ToggleChip type={type}>
          <Text.P1Semibold>
            {type === 'ongoing'
              ? `${t('ongoing', { count: chats.length })}`
              : `${t('completedCount', { count: chats.length })}`}
          </Text.P1Semibold>
        </AllChats.ToggleChip>
      </AllChats.ToggleGroup>

      {!hidden && (
        <>
          <Cards.MultiCardSection>
            {chats.length > 0 &&
              chats.map((chat) => (
                <Card
                  chat={chat}
                  key={chat.id}
                  getSession={getSession}
                  handleConfirmDialogOpen={handleConfirmDialogOpen}
                  resetChatGuideData={resetChatGuideData}
                  setActivePage={setActivePage}
                />
              ))}
          </Cards.MultiCardSection>
          {type === 'ongoing' && <Divider flexItem />}
        </>
      )}
    </>
  );
}

ToggleGroup.propTypes = {
  chats: PropTypes.array,
  getSession: PropTypes.func,
  handleConfirmDialogOpen: PropTypes.func,
  hidden: PropTypes.bool,
  resetChatGuideData: PropTypes.func,
  setActivePage: PropTypes.func,
  toggleGroup: PropTypes.func,
  type: PropTypes.string,
};
