import useWebSocket from 'react-use-websocket';

export default function useWebsocketSetup(socketUrl) {
  const authToken = JSON.parse(localStorage.getItem('authorization')).token;

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    process.env.REACT_APP_WEBSOCKET_URL + socketUrl,
    {
      onOpen: () => console.log('WebSocket Connected'),
      onError: (event) => console.log('WebSocket error:', event),
      onMessage: (event) => console.log('WebSocket message:', event),
      queryParams: { token: authToken },
      // Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: () => true,
    }
  );

  return { sendJsonMessage, lastJsonMessage, readyState };
}
