import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as Styled from 'components/pageECoach/_styles/Report.style';
import * as Text from 'components/_styles/Text.style';
import colours from 'constants/colours';

export default function SuggestionItem({ item: suggestion }) {
  const { t } = useTranslation('pageEcoach');

  return (
    <Styled.Content>
      <Text.SubheadingSemibold>{suggestion.title}</Text.SubheadingSemibold>
      <Text.P2>
        {suggestion.short_description}
        <b style={{ color: colours.blue[500] }}>{t('See more.')}</b>
      </Text.P2>
    </Styled.Content>
  );
}

SuggestionItem.propTypes = {
  item: PropTypes.object.isRequired,
};
