import styled from 'styled-components';
import Buttons from '@mui/material/Button';
import colours from 'constants/colours';
import device from 'constants/devices';

export const Button = styled(Buttons)`
  box-shadow: none;
  display: flex;
  flex-grow: 1;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  gap: 10px;
  line-height: 18px;
  text-transform: unset;
  white-space: nowrap;
`;

export const CTA = styled(Button)`
  background-color: ${colours.primary[600]};
  border-radius: 40px;
  color: ${colours.shade[0]};
  max-height: 80px;
  padding: 28px 33px;

  svg path {
    stroke: ${colours.shade[0]};
  }

  &:hover {
    background-color: ${colours.primary[700]};
  }

  &:focus {
    background-color: ${colours.primary[700]};
  }

  &:active {
    background-color: ${colours.primary[800]};
  }
`;

export const CTAIcon = styled(CTA)`
  height: 80px;
  max-width: 80px;
  padding: 28px;
`;

export const CTADisabled = styled(CTA)`
  background-color: ${colours.neutralCool[50]};
  color: ${colours.neutralCool[300]};

  svg path {
    stroke: ${colours.neutralCool[300]};
  }
`;

export const CTAIconDisabled = styled(CTAIcon)`
  background-color: ${colours.neutralCool[50]};
  color: ${colours.neutralCool[300]};

  svg path {
    stroke: ${colours.neutralCool[300]};
  }
`;

export const Primary = styled(Button)`
  background-color: ${colours.primary[600]};
  border-radius: 36px;
  color: ${colours.shade[0]};
  max-height: 60px;
  min-width: unset;
  padding: 20px 23px;

  svg path {
    stroke: ${colours.shade[0]};
  }

  &:hover {
    background-color: ${colours.primary[700]};
  }

  &:focus {
    background-color: ${colours.primary[700]};
  }

  &:active {
    background-color: ${colours.primary[800]};
  }
`;

export const PrimaryIcon = styled(Primary)`
  height: 60px;
  max-width: 60px;
  width: 60px;
  padding: 15px;
`;

export const PrimaryDisabled = styled(Primary)`
  background-color: ${colours.neutralCool[50]};
  color: ${colours.neutralCool[300]};
  pointer-events: none;

  svg path {
    stroke: ${colours.neutralCool[300]};
  }
`;

export const PrimaryIconDisabled = styled(PrimaryIcon)`
  background-color: ${colours.neutralCool[50]};
  color: ${colours.neutralCool[300]};
  pointer-events: none;

  svg path {
    stroke: ${colours.neutralCool[300]};
  }
`;

export const Secondary = styled(Button)`
  background-color: ${colours.neutralCool[100]};
  border-radius: 36px;
  color: ${colours.neutralCool[700]};
  max-height: 60px;
  min-width: unset;
  padding: 20px 23px;

  svg path {
    stroke: ${colours.neutralCool[700]};
  }

  &:hover {
    background-color: ${colours.neutralCool[200]};
    color: ${colours.neutralCool[800]};

    svg path {
      stroke: ${colours.neutralCool[800]};
    }
  }

  &:focus {
    background-color: ${colours.neutralCool[200]};
    color: ${colours.neutralCool[800]};

    svg path {
      stroke: ${colours.neutralCool[800]};
    }
  }

  &:active {
    background-color: ${colours.neutralCool[300]};
    color: ${colours.neutralCool[800]};

    svg path {
      stroke: ${colours.neutralCool[800]};
    }
  }
`;

export const SecondaryIcon = styled(Secondary)`
  height: 60px;
  max-width: 60px;
  width: 60px;
  padding: 15px;
`;

export const SecondaryDisabled = styled(Secondary)`
  background-color: ${colours.neutralCool[50]};
  color: ${colours.neutralCool[200]};
  pointer-events: none;

  svg path {
    stroke: ${colours.neutralCool[200]};
  }
`;

export const SecondaryIconDisabled = styled(SecondaryIcon)`
  background-color: ${colours.neutralCool[50]};
  pointer-events: none;
`;

export const Tertiary = styled(Button)`
  background-color: transparent;
  border-radius: 16px;
  color: ${colours.neutralCool[600]};
  gap: 4px;
  max-height: 32px;
  padding: 6px 14px 6px 8px;

  svg path {
    stroke: ${colours.neutralCool[600]};
  }

  &:hover {
    background-color: ${colours.neutralCool[100]};
  }

  &:focus {
    background-color: ${colours.neutralCool[100]};
  }

  &:active {
    background-color: ${colours.neutralCool[200]};
    color: ${colours.neutralCool[700]};

    svg path {
      stroke: ${colours.neutralCool[700]};
    }
  }
`;

export const TertiaryIcon = styled(Tertiary)`
  height: 32px;
  max-width: 32px;
  padding: 6px;
`;

export const TertiaryDisabled = styled(Tertiary)`
  color: ${colours.neutralCool[300]};
  pointer-events: none;

  svg path {
    stroke: ${colours.neutralCool[300]};
  }
`;

export const TertiaryIconDisabled = styled(TertiaryIcon)`
  color: ${colours.neutralCool[300]};
  pointer-events: none;

  svg path {
    stroke: ${colours.neutralCool[300]};
  }
`;

export const Nav = styled(Button)`
  background-color: transparent;
  border-radius: 8px;
  color: ${colours.neutralCool[800]};
  max-height: 44px;
  padding: 12px;

  svg path {
    stroke: ${colours.neutralCool[800]};
  }

  &:hover {
    background-color: ${colours.neutralCool[50]};
  }

  &:focus {
    background-color: ${colours.neutralCool[100]};
  }

  &:active {
    background-color: ${colours.neutralCool[100]};
  }
`;

export const NavDisabled = styled(Nav)`
  color: ${colours.neutralCool[300]};
  pointer-events: none;

  svg path {
    stroke: ${colours.neutralCool[300]};
  }
`;

export const MultipleChoice = styled(Button)`
  background-color: ${colours.neutralCool[50]};
  border-radius: 40px;
  max-height: 60px;
  padding: 20px 23px;

  p {
    color: ${colours.neutralCool[500]};

    @media (max-width: ${device.mobileL}) {
      display: none;
    }
  }

  svg path {
    stroke: ${colours.neutralCool[500]};
  }

  &:hover {
    background-color: ${colours.neutralCool[100]};

    svg path {
      stroke: ${colours.neutralCool[500]};
    }
  }

  &:focus {
    background-color: ${colours.primary[600]};

    p {
      color: ${colours.shade[0]};
    }

    svg path {
      stroke: ${colours.shade[0]};
    }
  }

  &.active {
    background-color: ${colours.primary[700]};
    pointer-events: none;

    p {
      color: ${colours.shade[0]};
    }

    svg path {
      stroke: ${colours.shade[0]};
    }
  }
`;

export const MultipleChoiceDisabled = styled(MultipleChoice)`
  background-color: ${colours.neutralCool[50]};
  color: ${colours.neutralCool[300]};
  pointer-events: none;

  svg path {
    stroke: ${colours.neutralCool[300]};
  }
`;
