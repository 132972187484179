import styled from 'styled-components';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

export const InputGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputTextArea = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: `${colours.neutralCool[50]}`,
    '& fieldset': {
      border: `2px solid ${colours.neutralCool[200]}`,
    },
    '&.Mui-focused fieldset': {
      border: `2px solid ${colours.neutralCool[200]}`,
    },
  },
  '& .MuiInputBase-input': {
    color: `${colours.neutralCool[900]}`,
  },
});

export const ButtonsColumn = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Card = styled(Cards.Card)`
  background: linear-gradient(279deg, #36475a 1.35%, #1a2634 102.58%);
  background-position: center;
  background-size: cover;
  gap: 20px;
  max-width: 530px;
  padding: 20px;
`;

export const Label = styled(Text.P2Semibold)`
  color: ${colours.neutralCool[100]};
`;

export const PromptControls = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PromptControl = styled(Button)`
  background-color: ${colours.neutralCool[100]};
  border-radius: 20px;
  color: ${colours.neutralCool[800]};
  gap: 5px;
  justify-content: unset;
  line-height: unset;
  max-width: 500px;
  padding: 10px 20px;
  text-align: left;
  text-transform: none;
  width: 100%;

  svg {
    width: 18px;
  }

  svg path {
    stroke: ${colours.neutralCool[800]};
  }

  :hover {
    background-color: ${colours.neutralCool[200]};
    background-color: ${colours.shade[0]};
  }

  @media (max-width: ${device.tablet}) {
    width: 100%;
  }
`;

export const PromptControlDark = styled(PromptControl)`
  background-color: ${colours.neutralCool[800]};
  p {
    color: ${colours.neutralCool[50]};
  }
  :hover {
    background-color: ${colours.neutralCool[700]};
  }
`;

export const PromptControlPrimary = styled(PromptControl)`
  background-color: ${colours.primary[500]};
  p {
    color: ${colours.shade[0]} !important;
  }
  :hover {
    background-color: ${colours.primary[600]};
  }
`;
