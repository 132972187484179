import useApi from 'hooks/useApi';
import routesConfig from 'constants/routesConfig.json';

const useChatStatus = () => {
  const { callApi } = useApi();

  const updateStatus = (chat, newStatus) => {
    if (!chat) return;
    callApi(
      `${routesConfig.ECOACH.SESSIONS}${chat.id}/resolution-status/`,
      'put',
      { resolution_status: newStatus }
    );
  };

  return { updateStatus };
};

export default useChatStatus;
