import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import ClickableInput from 'components/pageECoach/ClickableInput';

// data
import helperPrompts from 'components/pageECoach/promptHelper/prompts';

// styles
import * as StyledPromptControls from 'components/pageECoach/_styles/PromptControls.style';
import * as Page from 'components/pageECoach/_styles/ChatArea.style';

export default function QuestionTheme({ handleSelectedTheme, selectedTheme }) {
  const { t } = useTranslation('pageEcoach');

  const getThemes = () =>
    Object.keys(helperPrompts).map((theme) => (
      <ClickableInput
        inputAction={() => handleSelectedTheme(theme)}
        inputText={t(`promptHelper.${theme}`)}
        inputType="light"
        key={t(`promptHelper.${theme}`)}
      />
    ));

  const eCoachMessage = () => (
    <ChatBubble
      simpleString={t(
        "promptHelper.Let's start with a general theme. What would you like advice on?"
      )}
      pointer={true}
    />
  );

  if (selectedTheme)
    return (
      <>
        {eCoachMessage()}
        <ChatBubble
          simpleString={t(`promptHelper.${selectedTheme}`)}
          pointer={true}
          sentByUser
        />
      </>
    );

  return (
    <>
      {eCoachMessage()}
      <Page.UserSection>
        <StyledPromptControls.Card>
          <StyledPromptControls.Label>
            {t('Choose or write your own below')}
          </StyledPromptControls.Label>
          <StyledPromptControls.ButtonsColumn>
            {getThemes()}
            <ClickableInput
              inputAction={() => handleSelectedTheme('Something else')}
              inputText={t(`promptHelper.Something else`)}
              inputType="light"
              key="other"
            />
          </StyledPromptControls.ButtonsColumn>
        </StyledPromptControls.Card>
      </Page.UserSection>
    </>
  );
}

QuestionTheme.propTypes = {
  handleSelectedTheme: PropTypes.func,
  selectedTheme: PropTypes.string,
};
