import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import LogoIcon from 'assets/logo.svg';
import LogoText from 'assets/logoText.svg';

// components
import AvatarForm from 'components/pageOnboarding/AvatarForm';
import ConfidentialityCard from 'components/pageOnboarding/ConfidentialityCard';
import EndCard from 'components/pageOnboarding/EndCard';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import GeneralIntroCards from 'components/pageOnboarding/GeneralIntroCards';
import IdentityForm from 'components/pageOnboarding/IdentityForm';
import IdentityFormIntroCard from 'components/pageOnboarding/IdentityFormIntroCard';
import ThemesForm from 'components/pageOnboarding/ThemesForm';

// context
import { useUser } from 'contexts/UserContext';

// styles
import * as Page from 'components/pageOnboarding/_styles/_index.style';
import * as StyledLogo from 'components/navbar/_styles/Navbar.style';

export default function Onboarding({ testUser }) {
  const { t } = useTranslation('pageOnboarding');
  const { data } = useUser();
  const user = testUser || data;

  const [key, setKey] = useState(); // this resets scroll position to top
  const [onboardingSection, setOnboardingSection] = useState(0);
  const [submittedIdentityForm, setSubmittedIdentityForm] = useState({
    nickname: user.first_name,
    pronouns: '',
    other: '',
  });
  const [submittedAvatarForm, setSubmittedAvatarForm] = useState({
    avatar: 'happy',
    avatar_color: '#7A7A76',
  });
  const [submittedThemesForm, setSubmittedThemesForm] = useState({
    themes: [],
  });

  const pronouns = {
    she: { value: ['she', 'her'], label: t('she') },
    he: { value: ['he', 'him'], label: t('he') },
    they: { value: ['they', 'them'], label: t('they') },
    other: { value: 'other', label: t('other') },
    refuse: { value: ['-'], label: t('prefer') },
  };

  const handleSectionCompletion = (currentSection) => {
    setKey(Math.random());
    setOnboardingSection(currentSection + 1);
  };

  const handleSectionReturn = (currentSection) => {
    setKey(Math.random());
    setOnboardingSection(currentSection - 1);
  };

  return (
    <ErrorBoundary>
      <Page.Page key={key}>
        <StyledLogo.OnboardingLogo>
          <StyledLogo.LogoText src={LogoText} />
          <StyledLogo.LogoIcon src={LogoIcon} />
        </StyledLogo.OnboardingLogo>
        {onboardingSection === 0 && (
          <GeneralIntroCards markComplete={handleSectionCompletion} />
        )}
        {onboardingSection === 1 && (
          <IdentityFormIntroCard markComplete={handleSectionCompletion} />
        )}
        {onboardingSection === 2 && (
          <IdentityForm
            handleSectionReturn={handleSectionReturn}
            markComplete={handleSectionCompletion}
            pronouns={pronouns}
            setSubmittedIdentityForm={setSubmittedIdentityForm}
            submittedIdentityForm={submittedIdentityForm}
          />
        )}
        {onboardingSection === 3 && (
          <ThemesForm
            handleSectionReturn={handleSectionReturn}
            markComplete={handleSectionCompletion}
            setSubmittedThemesForm={setSubmittedThemesForm}
            submittedThemesForm={submittedThemesForm}
          />
        )}
        {onboardingSection === 4 && (
          <AvatarForm
            handleSectionReturn={handleSectionReturn}
            markComplete={handleSectionCompletion}
            setSubmittedAvatarForm={setSubmittedAvatarForm}
            submittedAvatarForm={submittedAvatarForm}
          />
        )}
        {onboardingSection === 5 && (
          <ConfidentialityCard markComplete={handleSectionCompletion} />
        )}
        {onboardingSection === 6 && (
          <EndCard submittedIdentityForm={submittedIdentityForm} />
        )}
      </Page.Page>
    </ErrorBoundary>
  );
}

Onboarding.propTypes = {
  testUser: PropTypes.object,
};
