import PropTypes from 'prop-types';
import React from 'react';

// styles
import * as StyledPromptControls from 'components/pageECoach/_styles/PromptControls.style';
import * as Text from 'components/_styles/Text.style';

export default function ClickableInput({ inputAction, inputText, inputType }) {
  if (inputType === 'dark')
    return (
      <StyledPromptControls.PromptControlDark onClick={inputAction}>
        <Text.P2Semibold>{inputText}</Text.P2Semibold>
      </StyledPromptControls.PromptControlDark>
    );

  if (inputType === 'primary')
    return (
      <StyledPromptControls.PromptControlPrimary onClick={inputAction}>
        <Text.P2Semibold>{inputText}</Text.P2Semibold>
      </StyledPromptControls.PromptControlPrimary>
    );

  // default: if (inputType === 'light')
  return (
    <StyledPromptControls.PromptControl onClick={inputAction}>
      <Text.P2Semibold>{inputText}</Text.P2Semibold>
    </StyledPromptControls.PromptControl>
  );
}

ClickableInput.propTypes = {
  inputAction: PropTypes.func,
  inputText: PropTypes.string,
  inputType: PropTypes.string,
};
