import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// constants
import routesConfig from 'constants/routesConfig.json';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import ClickableInput from 'components/pageECoach/ClickableInput';
import ConfirmDialog from 'components/_pageLayout/ConfirmDialog';
import Loader from 'components/pageECoach/Loader';
import ToggleGroup from 'components/pageECoach/allChats/ToggleGroup';
import { Stack } from '@mui/material';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Text from 'components/_styles/Text.style';

export default function AllChats({
  activeSession,
  getSession,
  handleNewChat,
  resetChatGuideData,
  setActivePage,
  setActiveSession,
}) {
  const { data: sessionList, callApi: getSessionList } = useApi();
  const { data: updatedSessions, callApi: updateSession } = useApi();
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [sessionId, setSessionId] = useState();
  const [ongoingChatsHidden, setOngoingChatsHidden] = useState(false);
  const [otherChatsHidden, setOtherChatsHidden] = useState(false);

  const handleConfirmDialogClose = () => setIsOpenConfirmDialog(false);
  const handleConfirmDialogOpen = (e, pastSession) => {
    e.stopPropagation();
    setSessionId(pastSession);
    setIsOpenConfirmDialog(true);
  };

  const toggleOngoingChats = () => setOngoingChatsHidden((prev) => !prev);
  const toggleOtherChats = () => setOtherChatsHidden((prev) => !prev);

  useEffect(() => {
    getSessionList(routesConfig.ECOACH.SESSIONS);
  }, [getSessionList]);

  useEffect(() => {
    if (updatedSessions.status === 'success')
      getSessionList(routesConfig.ECOACH.SESSIONS);
  }, [getSessionList, updatedSessions]);

  const hideSession = () => {
    updateSession(`${routesConfig.ECOACH.SESSIONS}${sessionId}/hide`, 'put');
    posthog?.capture('user_removed_session');
    if (sessionId === activeSession) setActiveSession(null);
    handleConfirmDialogClose();
  };

  const pastSessions =
    sessionList.status === 'success' ? sessionList.response : [];

  if (sessionList.status === 'loading') return <Loader inline />;

  if (pastSessions?.length === 0)
    return (
      <ChatBubble>
        <Text.P2Semibold>
          {t("You don't have any previous sessions yet.")}
        </Text.P2Semibold>
        <ClickableInput
          inputAction={handleNewChat}
          inputText={t('Get started with a new chat')}
          inputType="primary"
        />
      </ChatBubble>
    );

  const ongoingChats = pastSessions.filter(
    (chat) => chat.resolution_status === 'active'
  );

  const otherChats = pastSessions.filter(
    (chat) => chat.resolution_status !== 'active'
  );

  return (
    <Stack spacing={4}>
      <ToggleGroup
        chats={ongoingChats}
        getSession={getSession}
        handleConfirmDialogOpen={handleConfirmDialogOpen}
        hidden={ongoingChatsHidden}
        resetChatGuideData={resetChatGuideData}
        setActivePage={setActivePage}
        toggleGroup={toggleOngoingChats}
        type="ongoing"
      />
      <ToggleGroup
        chats={otherChats}
        getSession={getSession}
        handleConfirmDialogOpen={handleConfirmDialogOpen}
        hidden={otherChatsHidden}
        resetChatGuideData={resetChatGuideData}
        setActivePage={setActivePage}
        toggleGroup={toggleOtherChats}
        type="other"
      />

      {isOpenConfirmDialog && (
        <ConfirmDialog
          cancelLabel={t('No')}
          successLabel={t('Yes')}
          successCallback={() => hideSession(sessionId)}
          contentText={t('Are you sure you want to delete this session?')}
          handleClose={handleConfirmDialogClose}
          isOpen={true}
        />
      )}
    </Stack>
  );
}

AllChats.propTypes = {
  activeSession: PropTypes.string,
  getSession: PropTypes.func,
  handleNewChat: PropTypes.func,
  resetChatGuideData: PropTypes.func,
  retrieveSession: PropTypes.func,
  setActivePage: PropTypes.func,
  setActiveSession: PropTypes.func,
};
