import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';

// styles
import * as Styled from 'components/pageECoach/_styles/Report.style';
import * as Text from 'components/_styles/Text.style';

export default function ConversationItemDetails({ item }) {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture('convo_item_loaded');
  }, [posthog]);

  if (!item) {
    return <h1>No conversationStep set ?</h1>;
  }

  return (
    <>
      <Text.P2>{item?.description}</Text.P2>
      <Styled.Content>
        <Text.P2Semibold>{t('How to prepare')}</Text.P2Semibold>
        <Styled.List>
          {item?.prep?.map((pro) => (
            <li key={pro}>{pro}</li>
          ))}
        </Styled.List>
      </Styled.Content>
      <Styled.Content>
        <Text.P2Semibold>
          {t('Anticipating and handling reactions')}
        </Text.P2Semibold>
        <Styled.List>
          {item?.reactions?.map((con) => (
            <li key={con}>{con}</li>
          ))}
        </Styled.List>
      </Styled.Content>
      <Styled.Content>
        <Text.P2Semibold>{t('Example dialogue')}</Text.P2Semibold>
        <Styled.List>
          {item?.example_language?.map((con) => (
            <li key={con}>{con}</li>
          ))}
        </Styled.List>
      </Styled.Content>
    </>
  );
}

ConversationItemDetails.propTypes = {
  item: PropTypes.object.isRequired,
};
