import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Logo from 'assets/logo.svg';
import WomanOnPhone from 'components/pageOnboarding/assets/woman_on_phone.png';
import WomanOnPhoneFR from 'components/pageOnboarding/assets/woman_on_phone_fr.png';

// context
import { useUser } from 'contexts/UserContext';

// styles
import * as Page from 'components/pageOnboarding/_styles/_index.style';
import * as Styled from 'components/pageOnboarding/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

function GeneralIntroCards({ markComplete }) {
  const { t } = useTranslation('pageOnboarding');
  const { data: user } = useUser();

  const [cardNumber, setCardNumber] = useState(1);

  const womanOnPhoneImage =
    user.language === 'en' ? WomanOnPhone : WomanOnPhoneFR;

  const backPageClick = () => setCardNumber((prev) => prev - 1);
  const nextPageClick = () => {
    if (cardNumber === 2) markComplete(0);
    return setCardNumber((prev) => prev + 1);
  };

  return (
    <Styled.Card>
      {cardNumber === 1 && (
        <Styled.ImageArea style={{ height: '100%', justifyContent: 'center' }}>
          <Styled.ImageWithPadding style={{ maxWidth: '200px' }} src={Logo} />
        </Styled.ImageArea>
      )}

      {cardNumber === 2 && (
        <Styled.ImageArea>
          <Styled.ImageSpread src={womanOnPhoneImage} />
        </Styled.ImageArea>
      )}

      <Styled.BottomSection>
        <Styled.InnerSection>
          <Text.H5Bold>
            {cardNumber === 1 && t("Hey, I'm eCoach!")}
            {cardNumber === 2 && t("Let's navigate your workplace challenges!")}
          </Text.H5Bold>
          <Page.OnboardingP>
            {cardNumber === 1 &&
              t(
                'My role is to empower you with the knowledge, tools, and confidence to effectively lead and inspire your team.'
              )}
            {cardNumber === 2 &&
              t(
                "Get guidance aligned with your company's policies and leadership best practices."
              )}
          </Page.OnboardingP>
        </Styled.InnerSection>
      </Styled.BottomSection>
      <Styled.Navigation>
        {cardNumber === 1 ? (
          <div></div>
        ) : (
          <Styled.BackButton aria-label={t('back')} onClick={backPageClick}>
            <NavigateBeforeIcon />
          </Styled.BackButton>
        )}
        <Styled.NavButton aria-label={t('next')} onClick={nextPageClick}>
          <NavigateNextIcon />
        </Styled.NavButton>
      </Styled.Navigation>
    </Styled.Card>
  );
}

GeneralIntroCards.propTypes = {
  markComplete: PropTypes.func,
};

export default GeneralIntroCards;
