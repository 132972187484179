import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as AllChats from 'components/pageECoach/_styles/AllChats.style';

export default function CardDate({ chat }) {
  const { t } = useTranslation('pageEcoach');
  const lang = useUserLanguage();
  const now = dayjs();
  dayjs.locale(lang);

  function getOngoingTimeString(startDate) {
    const start = dayjs(startDate);
    const duration = start.from(now, true);
    return `${t('ongoing for')} ${duration}`;
  }

  if (chat.resolution_status === 'active')
    return (
      <AllChats.Date>{getOngoingTimeString(chat.created_at)}</AllChats.Date>
    );

  return <AllChats.Date>{now.to(dayjs(chat.created_at))}</AllChats.Date>;
}

CardDate.propTypes = {
  chat: PropTypes.object,
};
