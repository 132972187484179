import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// components
import ChatLanding from 'components/pageECoach/chatStart/ChatLanding';
import PromptHelper from 'components/pageECoach/promptHelper/_index';
import SampleCases from 'components/pageECoach/chatStart/SampleCases';

// utils
import { UserInputType } from 'components/pageECoach/utils';

export default function ChatStart(props) {
  const { chatGuideData, sendUserInput, setChatGuideData } = props;
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  const openPromptHelper = () => {
    const updates = {
      displayPolicyHelper: false,
      displayPromptHelper: true,
      displaySampleCases: false,
      chatStartEnded: false,
      inProgress: true,
    };

    setChatGuideData((prev) => ({ ...prev, ...updates }));
    posthog?.capture('user_clicks_prompt_helper');
  };

  const activatePolicyHelper = () => {
    sendUserInput(
      t('I need information about my company policies'),
      UserInputType.INITIAL_PROMPT
    );
    posthog?.capture('user_clicks_policy_helper');
  };

  const startConvoPrep = () => {
    sendUserInput(
      t('I need help preparing for a conversation with someone.'),
      UserInputType.CONVERSATION_PREP_START
    );
    posthog?.capture('user_clicks_convo_prep_form');
  };

  const openSampleCases = () => {
    const updates = { displayPromptHelper: false, displaySampleCases: true };
    setChatGuideData((prev) => ({ ...prev, ...updates }));
  };

  const onChallengeSubmission = (userInput) => {
    const updates = { inProgress: false, chatStartEnded: true };
    setChatGuideData((prev) => ({ ...prev, ...updates }));
    posthog?.capture('user_submits_eCoach_challenge');
    sendUserInput(userInput, UserInputType.INITIAL_PROMPT);
  };

  if (chatGuideData.displayPromptHelper)
    return (
      <PromptHelper
        chatGuideData={chatGuideData}
        onSubmit={onChallengeSubmission}
        setChatGuideData={setChatGuideData}
      />
    );

  if (chatGuideData.displaySampleCases)
    return (
      <SampleCases
        chatGuideData={chatGuideData}
        onSubmit={onChallengeSubmission}
      />
    );

  return (
    <ChatLanding
      activatePolicyHelper={activatePolicyHelper}
      onSubmit={onChallengeSubmission}
      startConvoPrep={startConvoPrep}
      openPromptHelper={openPromptHelper}
      openSampleCases={openSampleCases}
    />
  );
}

ChatStart.propTypes = {
  chatGuideData: PropTypes.object,
  sendUserInput: PropTypes.func,
  setChatGuideData: PropTypes.func,
  setMainChatbarHidden: PropTypes.func,
};
