import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';

// hooks
import useUserNickname from 'hooks/useUserNickname';

// styles
import * as Text from 'components/_styles/Text.style';

export default function IntroMessage() {
  const { t } = useTranslation('pageEcoach');
  const nickname = useUserNickname();

  const header = t("What's a challenge you're facing at work?", {
    nickname,
  });

  const subheader = t(
    'The more details you give, the better your results will be. Always confidential.'
  );

  return (
    <ChatBubble disableScroll pointer>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Text.P2Semibold>{header}</Text.P2Semibold>
        <Text.P2>{subheader}</Text.P2>
      </div>
    </ChatBubble>
  );
}
