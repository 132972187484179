import React from 'react';
import PropTypes from 'prop-types';

// assets
import Logo from 'assets/logo.svg';
import LogoText from 'assets/logoText.svg';

// components
import LanguageToggle from 'components/navbar/LanguageToggle';
import Logout from 'components/navbar/Logout';

// context
import { useUser } from 'contexts/UserContext';

// helpers
import resetApp from 'helpers/resetApp';

// styles
import * as NavbarStyled from 'components/navbar/_styles/Navbar.style';
import * as TopbarStyled from 'components/navbar/_styles/Topbar.style';

export default function Topbar({ activeTab, getUser, routes, toggleSidebar }) {
  const { userRole } = useUser();
  const closeSidebar = () => toggleSidebar();

  return (
    <TopbarStyled.Section>
      <TopbarStyled.Container activeTab={activeTab}>
        <TopbarStyled.TopbarLogo>
          <NavbarStyled.LogoText onClick={resetApp} src={LogoText} />
          <NavbarStyled.LogoIcon onClick={resetApp} src={Logo} />
        </TopbarStyled.TopbarLogo>
        <TopbarStyled.RightSide>
          {routes.map(
            (route) =>
              route.users.includes(userRole) && (
                <TopbarStyled.Tab
                  active={(activeTab === route.tab).toString()}
                  key={route.tab}
                >
                  <TopbarStyled.NavLink
                    to={{ pathname: route.link }}
                    onClick={() => closeSidebar()}
                  >
                    <TopbarStyled.TabIcon
                      active={(activeTab === route.tab).toString()}
                    >
                      {route.logo()}
                    </TopbarStyled.TabIcon>
                    <TopbarStyled.TabText
                      active={(activeTab === route.tab).toString()}
                    >
                      {route.label}
                    </TopbarStyled.TabText>
                  </TopbarStyled.NavLink>
                </TopbarStyled.Tab>
              )
          )}
          <TopbarStyled.Divider />
          <LanguageToggle getUser={getUser} />
          <TopbarStyled.Divider />
          <Logout />
        </TopbarStyled.RightSide>
      </TopbarStyled.Container>
    </TopbarStyled.Section>
  );
}

Topbar.propTypes = {
  activeTab: PropTypes.string,
  getUser: PropTypes.func,
  routes: PropTypes.array,
  sidebarOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};
