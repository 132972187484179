import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { Communication } from 'untitledui-js';
import useAnimatedEllipsis from 'components/pageECoach/chatbar/useAnimatedEllipsis';
import Microphone from 'components/pageECoach/chatbar/Microphone';

// styles
import * as StyledChatbar from 'components/pageECoach/_styles/Chatbar.style';

export default function Chatbar({ initialInput, onSubmit, autoFocus }) {
  const [microphoneDeactivated, setMicrophoneDeactivated] = useState(true);
  const [splitText, setSplitText] = useState(['', '']);
  const cursorPosition = useRef(0);
  const [pendingInput, setPendingInput] = useState(initialInput || '');
  const chatbarRef = useRef(null);
  const ellipsis = useAnimatedEllipsis();

  const handleCursorPosition = (event) => {
    const position = event ? event.target.selectionEnd : pendingInput.length;
    cursorPosition.current = position;
    const splitAtIndex = (str, index) => [
      str.slice(0, index).trim(),
      str.slice(index).trim(),
    ];
    setSplitText(splitAtIndex(pendingInput, cursorPosition.current));
  };

  const handleSubmit = () => {
    onSubmit(pendingInput);
    setMicrophoneDeactivated(true);
    setPendingInput('');
  };

  const insertTranscript = (transcript) => {
    const punctuationCheck = (str) => {
      const punctuationRegex = /[.!?;:,]$/;
      return punctuationRegex.test(str);
    };
    const endsWithPunctuation = punctuationCheck(...splitText.slice(0, 1));

    const processedTranscript =
      splitText[0].length > 0 && !endsWithPunctuation
        ? transcript.trim().toLowerCase()
        : transcript;

    const newValue =
      splitText[0].length > 0
        ? [...splitText.slice(0, 1), processedTranscript, ...splitText.slice(1)]
        : [processedTranscript];

    const processed = newValue.join(' ').trim();
    setPendingInput(processed);
  };

  const displayValue = microphoneDeactivated
    ? pendingInput
    : `${pendingInput}${ellipsis}`;

  return (
    <StyledChatbar.Section>
      <Paper
        sx={{
          alignItems: 'center',
          borderRadius: '20px',
          boxSizing: 'border-box',
          boxShadow: 7,
          display: 'flex',
          justifyContent: 'space-between',
          p: '6px 12px',
          width: '100%',
        }}
      >
        <Microphone
          deactivated={microphoneDeactivated}
          handleCursorPosition={handleCursorPosition}
          insertTranscript={insertTranscript}
          setMicrophoneDeactivated={setMicrophoneDeactivated}
        />
        <TextField
          onChange={(input) => setPendingInput(input.target.value)}
          placeholder="Message eCoach"
          inputProps={{ 'aria-label': 'Message eCoach' }}
          variant="standard"
          multiline
          InputProps={{
            autoFocus,
            disableUnderline: true,
            inputRef: chatbarRef,
            style: { caretColor: 'red' },
          }}
          onInput={handleCursorPosition}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter' && !ev.shiftKey) {
              ev.preventDefault();
              if (pendingInput.trim().length !== 0) handleSubmit();
            }
          }}
          onSelect={handleCursorPosition}
          sx={{ flexGrow: 1 }}
          value={displayValue}
        />
        <StyledChatbar.Button
          type="submit"
          aria-label="submit"
          disabled={pendingInput.trim().length === 0}
          onClick={handleSubmit}
        >
          <Communication.Send01 strokeWidth={1.5} />
        </StyledChatbar.Button>
      </Paper>
    </StyledChatbar.Section>
  );
}

Chatbar.propTypes = {
  initialInput: PropTypes.string,
  onSubmit: PropTypes.func,
  autoFocus: PropTypes.bool,
};
