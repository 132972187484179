import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// components
import ConversationItem from 'components/pageECoach/convoPrep/ConversationItem';
import ClickableListCard from 'components/pageECoach/ClickableListCard';
import ConversationItemDetails from 'components/pageECoach/convoPrep/ConversationItemDetails';
// import DocumentList from 'components/pageECoach/documentDisplay/DocumentList';
import ECoachResponse from 'components/pageECoach/cardTemplates/ECoachResponse';
import Loader from 'components/pageECoach/Loader';

// hooks
import { useEcoachItemsConsumer } from 'hooks/useEcoachItemsConsumer';

export default function ConversationItemsCard({ activeSession, stepOutput }) {
  const { t } = useTranslation('pageEcoach');

  const { items, selectedItem, selectItem } = useEcoachItemsConsumer(
    activeSession.id,
    stepOutput
  );

  return (
    <>
      <ECoachResponse status={items.status} loader={<Loader />}>
        <ClickableListCard
          isNumbered
          ItemDetailsComponent={ConversationItemDetails}
          items={items.data}
          ListItemComponent={ConversationItem}
          listTitle={t('Conversation Plan')}
          selectedItem={selectedItem}
          selectItem={selectItem}
        />
        {/* //FIXME */}
        {/* {stepOutput.suggestion_items.documents.length > 0 && (
          <DocumentList
            documentList={stepOutput.suggestion_items.documents}
            session={activeSession.id}
          />
        )} */}
      </ECoachResponse>
    </>
  );
}

ConversationItemsCard.propTypes = {
  activeSession: PropTypes.object,
  stepOutput: PropTypes.object,
};
