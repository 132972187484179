import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import ClickableInput from 'components/pageECoach/ClickableInput';

// styles
import * as Page from 'components/pageECoach/_styles/ChatArea.style';
import * as StyledPromptControls from 'components/pageECoach/_styles/PromptControls.style';

export default function SampleCases({ chatGuideData, onSubmit }) {
  const { t } = useTranslation('pageEcoach');

  const samplePrompts = [
    {
      label: t(
        'sampleCases.mediate a conflict between employees who constantly disagree about shift handovers shift handovers.'
      ),
      fullPrompt: t(
        'sampleCases.Two of my cashiers have had repeated disagreements over shift handovers, leading to a tense atmosphere. How can I mediate between them to resolve the ongoing issue and prevent future conflicts?'
      ),
    },
    {
      label: t('sampleCases.boost the enthusiasm of evening shift employees.'),
      fullPrompt: t(
        'sampleCases.Recently, I noticed that our evening shift employees are not as energetic with customers as the morning staff, which has been reflected in a dip in our evening sales. What steps can I take to boost their energy levels and improve customer interactions during these hours?'
      ),
    },
    {
      label: t('sampleCases.handle a customer complaint about employee.'),
      fullPrompt: t(
        'sampleCases.A customer complained about a rude interaction with one of my staff members who has otherwise been a good employee. How can I address this incident with him constructively and use it as a learning opportunity to improve our customer service?'
      ),
    },
  ];

  return (
    <>
      <ChatBubble
        pointer={true}
        simpleString={t('sampleCases.I want to try out some examples')}
        sentByUser
      />
      {}
      <ChatBubble
        pointer={true}
        simpleString={t(
          'sampleCases.Here are a few test cases. Feel free to click one to test the eCoach.'
        )}
      />
      {chatGuideData.chatStartEnded === false && (
        <Page.UserSection>
          <StyledPromptControls.Card>
            <StyledPromptControls.Label>
              {t('Choose or write your own below')}
            </StyledPromptControls.Label>
            <StyledPromptControls.ButtonsColumn>
              {samplePrompts.map((sample) => (
                <ClickableInput
                  inputAction={() => onSubmit(sample.fullPrompt)}
                  inputText={sample.label}
                  inputType="light"
                  key={sample.label}
                />
              ))}
            </StyledPromptControls.ButtonsColumn>
          </StyledPromptControls.Card>
        </Page.UserSection>
      )}
    </>
  );
}

SampleCases.propTypes = {
  chatGuideData: PropTypes.object,
  onSubmit: PropTypes.func,
};
