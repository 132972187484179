import styled from 'styled-components';
import colours from 'constants/colours';
import IconButton from '@mui/material/IconButton';

export const Section = styled('div')`
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  width: 100%;
`;

export const Microphone = styled(IconButton)`
  margin-right: 10px;

  svg path {
    stroke: ${(props) =>
      props.active ? colours.primary[500] : colours.neutralCool[400]};
  }
`;

export const Button = styled(IconButton)`
  svg path {
    stroke: ${(props) =>
      props.disabled ? colours.neutralCool[400] : colours.primary[500]};
  }
`;
