import PropTypes from 'prop-types';
import React from 'react';

// styles
import * as ChatStartStyle from 'components/pageECoach/_styles/ChatStart.style';

export default function PromptControl({ action, icon, description }) {
  return (
    <ChatStartStyle.PromptControl onClick={action}>
      <ChatStartStyle.Icon>{icon}</ChatStartStyle.Icon>
      <ChatStartStyle.TextSection>
        <ChatStartStyle.Description>{description}</ChatStartStyle.Description>
      </ChatStartStyle.TextSection>
    </ChatStartStyle.PromptControl>
  );
}

PromptControl.propTypes = {
  action: PropTypes.func,
  icon: PropTypes.string,
  description: PropTypes.string,
};
