import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import DocumentList from 'components/pageECoach/documentDisplay/DocumentList';
import StyledMarkdown from 'components/_pageLayout/StyledMarkdown';

// context
import { useUser } from 'contexts/UserContext';

// styles
import * as Text from 'components/_styles/Text.style';

export default function RAGCard({ activeSession, output }) {
  const { t } = useTranslation('pageEcoach');
  const { data: user } = useUser();

  return (
    <ChatBubble pointer={true}>
      <Text.CaptionSemiboldCap>
        {t('convoPrep.Best practices at', {
          company: user.organization.name,
        })}
      </Text.CaptionSemiboldCap>
      <StyledMarkdown content={output.rag.summary} />
      <DocumentList
        documentList={output.rag.documents}
        session={activeSession.id}
      />
    </ChatBubble>
  );
}

RAGCard.propTypes = {
  activeSession: PropTypes.object,
  output: PropTypes.object,
};
