import styled from 'styled-components';
import device from 'constants/devices';

export const Ecoach = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  max-width: 1200px;
  overflow: clip;
  padding: 0 30px 30px 30px;

  @media (max-width: ${device.tablet}) {
    padding: 0 10px 10px 10px;
  }
`;

export default Ecoach;
