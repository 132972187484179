// eslint-disable-next-line import/prefer-default-export
import { useCallback, useEffect, useState } from 'react';
import routesConfig from 'constants/routesConfig.json';
import useWebsocketSetup from './useWebsocketSetup';

// constants
import { OutputType } from '../components/pageECoach/utils';

export const LoadingStatus = Object.freeze({
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
  IDLE: 'idle',
});

// eslint-disable-next-line import/prefer-default-export
export const useEcoachItemsConsumer = (sessionId, stepOutput) => {
  const [items, setItems] = useState({
    status: LoadingStatus.IDLE,
    data: null,
  });
  const [selectedItem, setSelectedItem] = useState({
    status: LoadingStatus.IDLE,
    data: null,
  });

  const getConsumerEndpoint = useCallback((outputType) => {
    switch (outputType) {
      case OutputType.CONVERSATION_PLAN:
        return routesConfig.ECOACH.CONVERSATION_ITEMS_WS;
      case OutputType.SUGGESTIONS:
        return routesConfig.ECOACH.SUGGESTION_ITEMS_WS;
      default:
        // FIXME What to do here?
        return '???';
    }
  }, []);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebsocketSetup(
    getConsumerEndpoint(stepOutput.output_type)
  );

  // Load initial items
  useEffect(() => {
    let outputItems;
    switch (stepOutput.output_type) {
      case OutputType.CONVERSATION_PLAN:
        outputItems = stepOutput.conversation_items;
        break;
      case OutputType.SUGGESTIONS:
        outputItems = stepOutput.suggestion_items;
        break;
      default:
        outputItems = [];
    }
    setItems({
      status: LoadingStatus.LOADED,
      data: outputItems,
    });
  }, [
    stepOutput.output_type,
    stepOutput.suggestion_items,
    stepOutput.conversation_items,
  ]);

  // Receive WS response
  useEffect(() => {
    if (lastJsonMessage === null) {
      return;
    }

    const { action, data } = lastJsonMessage;

    if (action === 'get') {
      setSelectedItem({ status: LoadingStatus.LOADED, data });
    }
  }, [lastJsonMessage]);

  const selectItem = (itemId) => {
    if (!itemId) {
      setSelectedItem({ status: LoadingStatus.IDLE, data: null });
      return;
    }

    setSelectedItem({
      status: LoadingStatus.LOADING,
      data: { id: itemId },
    });

    if (readyState === WebSocket.OPEN) {
      sendJsonMessage({
        action: 'get',
        request_id: Date.now(),
        pk: sessionId,
        item_id: itemId,
      });
    }
  };

  return { items, selectedItem, selectItem };
};
