// eslint-disable-next-line import/prefer-default-export
import { useEffect, useState } from 'react';
import routesConfig from 'constants/routesConfig.json';
import useWebsocketSetup from './useWebsocketSetup';

export const LoadingStatus = Object.freeze({
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
  IDLE: 'idle',
});

// eslint-disable-next-line import/prefer-default-export
export const useFeedbackConsumer = () => {
  const [feedbackStatus, setFeedbackStatus] = useState({
    loadingStatus: LoadingStatus.IDLE,
    data: null,
  });

  const { sendJsonMessage, lastJsonMessage } = useWebsocketSetup(
    routesConfig.ECOACH.FEEDBACK_WS
  );

  useEffect(() => {
    if (lastJsonMessage === null) return;

    const { action, data } = lastJsonMessage;

    if (action === 'feedback_status') {
      setFeedbackStatus({
        loadingStatus: LoadingStatus.LOADED,
        data,
      });
    }
  }, [lastJsonMessage]);

  const fetchFeedbackStatus = (sessionId) => {
    sendJsonMessage({
      action: 'feedback_status',
      request_id: Date.now(),
      session_pk: sessionId,
    });
  };

  const tryCreate = (sessionId, feedbackData) => {
    sendJsonMessage({
      action: 'try_create',
      request_id: Date.now(),
      session_pk: sessionId,
      data: feedbackData,
    });
  };

  return { feedbackStatus, fetchFeedbackStatus, tryCreate };
};
