import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import ClickableInput from 'components/pageECoach/ClickableInput';

// data
import helperPrompts from 'components/pageECoach/promptHelper/prompts';

// styles
import * as Page from 'components/pageECoach/_styles/ChatArea.style';
import * as StyledPromptControls from 'components/pageECoach/_styles/PromptControls.style';

export default function QuestionGoal({
  handleSelectedGoal,
  selectedGoal,
  selectedTheme,
}) {
  const { t } = useTranslation('pageEcoach');

  const getGoals = () =>
    helperPrompts[selectedTheme].map((option) => (
      <ClickableInput
        inputAction={() => handleSelectedGoal(option)}
        inputText={t(`promptHelper.${option.label}`)}
        inputType="light"
        key={option.label}
      />
    ));

  const eCoachMessage = () => (
    <ChatBubble
      simpleString={t("promptHelper.Understood. What's your goal?")}
      pointer={true}
    />
  );

  if (selectedGoal)
    return (
      <>
        {eCoachMessage()}
        <ChatBubble
          simpleString={t(
            `promptHelper.${selectedGoal.label || 'Something else'}`
          )}
          pointer={true}
          sentByUser
        />
      </>
    );

  return (
    <>
      {eCoachMessage()}
      <Page.UserSection>
        <StyledPromptControls.Card>
          <StyledPromptControls.Label>
            {t('Choose or write your own below')}
          </StyledPromptControls.Label>
          <StyledPromptControls.ButtonsColumn>
            {getGoals()}
            <ClickableInput
              inputAction={() => handleSelectedGoal('Something else')}
              inputText={t('promptHelper.Something else')}
              inputType="light"
              key="other"
            />
          </StyledPromptControls.ButtonsColumn>
        </StyledPromptControls.Card>
      </Page.UserSection>
    </>
  );
}

QuestionGoal.propTypes = {
  handleSelectedGoal: PropTypes.func,
  selectedGoal: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedTheme: PropTypes.string,
};
