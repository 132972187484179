import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Arrow } from 'untitledui-js';

// components
import StyledMarkdown from 'components/_pageLayout/StyledMarkdown';

// hooks
import useAutoScroll from 'hooks/useAutoScroll';
import { useUser } from 'contexts/UserContext';

// styles
import * as ChatBubbleStyled from 'components/pageECoach/_styles/ChatBubble.style';
import * as Page from 'components/pageECoach/_styles/ChatArea.style';
import * as Text from 'components/_styles/Text.style';

function ChatBubble({
  children,
  disableScroll,
  header,
  navigation,
  pointer,
  sentByUser,
  simpleString,
  subheader,
}) {
  const { data: user } = useUser();
  const ref = useAutoScroll();
  const { t } = useTranslation();
  const name = user?.nickname || user?.first_name || t('You');

  const getHeader = () => (
    <ChatBubbleStyled.Headers>
      {navigation && (
        <ChatBubbleStyled.Navigation onClick={navigation.action}>
          <Arrow.ChevronLeft />
          <Text.P1>{navigation.text}</Text.P1>
        </ChatBubbleStyled.Navigation>
      )}
      <Text.SubheadingSemibold>{header}</Text.SubheadingSemibold>
      {subheader && (
        <ChatBubbleStyled.Subheader>{subheader}</ChatBubbleStyled.Subheader>
      )}
    </ChatBubbleStyled.Headers>
  );

  if (!sentByUser && simpleString)
    return (
      <Page.ECoachSection>
        <ChatBubbleStyled.Section eCoach={true}>
          <ChatBubbleStyled.ECoachBubbleWithPointer
            ref={disableScroll ? null : ref}
          >
            <ChatBubbleStyled.DescriptionText>
              <StyledMarkdown content={simpleString} />
            </ChatBubbleStyled.DescriptionText>
          </ChatBubbleStyled.ECoachBubbleWithPointer>
          <ChatBubbleStyled.Label>eCoach</ChatBubbleStyled.Label>
        </ChatBubbleStyled.Section>
      </Page.ECoachSection>
    );

  if (pointer && !sentByUser)
    return (
      <Page.ECoachSection>
        <ChatBubbleStyled.Section eCoach={true}>
          <ChatBubbleStyled.ECoachBubbleWithPointer
            ref={disableScroll ? null : ref}
          >
            {header && getHeader()}
            {children}
          </ChatBubbleStyled.ECoachBubbleWithPointer>
          <ChatBubbleStyled.Label>eCoach</ChatBubbleStyled.Label>
        </ChatBubbleStyled.Section>
      </Page.ECoachSection>
    );

  if (!pointer && !sentByUser)
    return (
      <Page.ECoachSection>
        <ChatBubbleStyled.Section eCoach={true}>
          <ChatBubbleStyled.ChatBubble ref={disableScroll ? null : ref}>
            {header && getHeader()}
            {children}
          </ChatBubbleStyled.ChatBubble>
        </ChatBubbleStyled.Section>
      </Page.ECoachSection>
    );

  if (sentByUser && simpleString)
    return (
      <Page.UserSection>
        <ChatBubbleStyled.Section>
          <ChatBubbleStyled.UserBubbleWithPointer
            ref={disableScroll ? null : ref}
          >
            <ChatBubbleStyled.DescriptionText>
              {simpleString}
            </ChatBubbleStyled.DescriptionText>
          </ChatBubbleStyled.UserBubbleWithPointer>
          <ChatBubbleStyled.Label>{name}</ChatBubbleStyled.Label>
        </ChatBubbleStyled.Section>
      </Page.UserSection>
    );

  return (
    <Page.UserSection>
      <ChatBubbleStyled.Section>
        <ChatBubbleStyled.UserBubbleWithPointer
          ref={disableScroll ? null : ref}
        >
          {children}
        </ChatBubbleStyled.UserBubbleWithPointer>
        <ChatBubbleStyled.Label>{name}</ChatBubbleStyled.Label>
      </ChatBubbleStyled.Section>
    </Page.UserSection>
  );
}

export default ChatBubble;

ChatBubble.propTypes = {
  header: PropTypes.string,
  disableScroll: PropTypes.bool,
  navigation: PropTypes.shape({
    action: PropTypes.func,
    text: PropTypes.string,
  }),
  pointer: PropTypes.bool,
  subheader: PropTypes.string,
  children: PropTypes.node, // Specifies that children could be anything that React can render
  sentByUser: PropTypes.bool,
  simpleString: PropTypes.string,
};
