import React from 'react';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

// assets
import { ReactComponent as Logo } from 'assets/logo.svg';

// components
import NavbarRoutes from 'components/_routes/NavbarRoutes';
import PageRoutes from 'components/_routes/PageRoutes';

// context
import { useUser } from 'contexts/UserContext';

// hooks
import { useViewportHeight } from 'hooks/useViewportHeight';

// styles
import * as Container from 'components/_pageLayout/_styles/PageLayout.style';
import { Link } from '@mui/material';

export default function PageLayout() {
  const { t } = useTranslation('pageLayout');
  const location = useLocation();
  const { data: user, callApi: getUser } = useUser();
  useViewportHeight();

  const inChatroom = location.pathname.includes('chatroom');
  const inECoach = location.pathname.toLowerCase().includes('ecoach');
  const inEvaluation = location.pathname.includes('evaluation');
  const inOnboarding = location.pathname.includes('onboarding');

  const demoBanner = () => (
    <Container.DemoMessage icon={<Logo />}>
      <Trans
        i18nKey="demoBanner"
        t={t}
        components={[
          <Link
            key="0"
            href="https://meetings.hubspot.com/justin-lessard-wajcer"
            underline="none"
            target="_blank"
          />,
        ]}
        ns="pageLayout"
      />
    </Container.DemoMessage>
  );

  return (
    <Container.ScreenContainer id="screen">
      {user.is_demo && demoBanner()}
      {!inChatroom && !inOnboarding && !inEvaluation && (
        <NavbarRoutes getUser={getUser} />
      )}
      <Container.PageContainer inECoach={inECoach}>
        <PageRoutes />
      </Container.PageContainer>
    </Container.ScreenContainer>
  );
}
