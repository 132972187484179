import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import Chatbar from 'components/pageECoach/chatbar/Chatbar';
import ChatBubble from 'components/pageECoach/ChatBubble';

// style
import * as Text from 'components/_styles/Text.style';

export default function QuestionNoTheme({ chatGuideData, onSubmit }) {
  const { t } = useTranslation('pageEcoach');

  return (
    <>
      <ChatBubble pointer={true}>
        <Text.P2>
          {t(
            "promptHelper.If you're struggling to put your challenge into words, sometimes it helps to think of elements of a story."
          )}
        </Text.P2>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Text.P1>{t('promptHelper.Who are the main characters?')}</Text.P1>
          <Text.P1>{t('promptHelper.What is the main conflict?')}</Text.P1>
          <Text.P1>{t('promptHelper.What is the ideal resolution?')}</Text.P1>
          <Text.P1>
            {t('promptHelper.What are the barriers to that resolution?')}
          </Text.P1>
        </div>
      </ChatBubble>
      {chatGuideData.chatStartEnded === false && (
        <Chatbar autoFocus onSubmit={onSubmit} />
      )}
    </>
  );
}

QuestionNoTheme.propTypes = {
  chatGuideData: PropTypes.object,
  onSubmit: PropTypes.func,
};
